import React, { useState, useEffect, Fragment } from 'react';

import logo from './logo.svg';
import './App.css';

import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import AllOut from './assets/images/alloutblack.png';
import Web3 from 'web3';
import { Modal } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import {
  createStyles,
  fade,
  Theme,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import PostRequest from './postRequest'

const BorderLinearProgress = withStyles((theme) => ({
  // root: {
  //   height: 10,
  //   borderRadius: 5,
  // },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: '#74ff14',
  },
}))(LinearProgress);



const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "&:focus":{
      outline: 'none'
     },
     '&:active': {
      outline: 'none',
    },
    '&:hover': {
      outline: 'none',
    },
    outline: 'none',
    outline: 0
  },
  paper: {
    backgroundColor: 'white',
    outline: 'none',
    outline: 0,
    boxShadow: theme.shadows[5]
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  credentals: {
    '&:before': {
      borderColor: 'grey',
    },
    '&:after': {
        borderColor: '#0cbfe9',
    }
  },
  textFieldLabel: {
    // this will be applied when input focused (label color change)
    "&$textFieldLabelFocused": {
      // color: '#0cbfe9'
      color: '#000000'
    }
  },
  textFieldLabelFocused: {},
  textFieldRoot: {
    // this will be applied when hovered (input text color change)
    "&:hover": {
      // color: '#0cbfe9',
      color: '#000000',
    },
    // this will applied when hovered (input border color change)
    "&:hover $textFieldNotchedOutline": {
      // borderColor: '#0cbfe9'
      borderColor: '#000000'
    },
    // this will be applied when focused (input border color change)
    "&$textFieldFocused $textFieldNotchedOutline": {
      borderColor: '#0cbfe9'
    }
  },
  textFieldFocused: {},
  textFieldNotchedOutline: {},
  icon:{
      color: 'grey',
    "&$activeIcon": {
      color: 'black'
    },
    "&$completedIcon": {
      color: theme.palette.secondary.main
    }
  },
  activeIcon: {},
  completedIcon: {}
}));
const  App = () => {
  const [messageListenObj, setMessageListenObj]= useState(null)
  const [isModal, setIsModal]= useState(false)
  const [isComplete, setIscomplete] = useState(false);

//   useEffect(() => {
//     // Update the document title using the browser API
//     const messageListener = document.addEventListener("message", function(data) {
//       //verify token here...
//       confirmWalletOWnership();
//     });
//     setMessageListenObj(messageListener)
//     return () => {
//       document.removeEventListener('message', messageListenObj);
//   }
//   },[]);

// const confirmWalletOWnership = () => {

//   const connector = new WalletConnect({
//     bridge: "https://bridge.walletconnect.org", // Required
//     qrcodeModal: QRCodeModal,
//   });

//   if (!connector.connected) {
//     // create new session
//     connector.createSession();
//   }

//   // Subscribe to connection events
//   connector.on("connect", (error, payload) => {
//     if (error) {
//       throw error;
//     }

//       // Get provided accounts and chainId
//     const { accounts, chainId } = payload.params[0];
//     let toReactData = JSON.stringify({
//       status: 'success',
//       accounts
//     })
//     window.ReactNativeWebView.postMessage(toReactData)

//   });

//   connector.on("session_update", (error, payload) => {
//     if (error) {
//       throw error;
//     }

//     // Get updated accounts and chainId
//     const { accounts, chainId } = payload.params[0];
//   });

//   connector.on("disconnect", (error, payload) => {
//     if (error) {
//       throw error;
//     }

//     // Delete connector
//   });
// }


  // useEffect(async() => {
  //   // Update the document title using the browser API
  //   if(window.ethereum){
  //     try {
  //         const web3 = new Web3(window['ethereum']);
  //         const accounts = await window.ethereum.enable();
  //   // preSaleContract = new web3.eth.Contract(preSaleAbi,preSaleAddress);
  //         console.log("Web3 connected 1")
  //     }catch(e) {
  //         //console.log(e)
  //     }
  //   }else if (window.web3) {
  //       const web3 = new Web3(web3.currentProvider);
  //       const accounts = await web3.eth.getAccounts();
  //   // preSaleContract = new web3.eth.Contract(preSaleAbi,preSaleAddress);
  //       console.log("Web3 connected 2");

  //   }

  // },[]);
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  

    return (
      <Fragment>
        <SplashScreen
          toggleModal={() => setIsModal(!isModal)}
        />
        <PairModal
          isModal={isModal}
          toggleModal={() => setIsModal(!isModal)}
          toggleSnackBar={()=> setIscomplete(true)}
        />
        <Snackbar open={isComplete} autoHideDuration={15000} onClose={() => setIscomplete(false)}>
          <Alert onClose={() => setIscomplete(false)} severity="success">
            Wallet sucessfully paired!
          </Alert>
        </Snackbar>
      </Fragment>
    );
  }



  Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool.isRequired,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
  };

const PairModal = ({
  isModal,
  toggleModal,
  toggleSnackBar
}) => {

  const classes = useStyles();
  return (
    <div>
    <Modal
      disableAutoFocus={true}
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={isModal}
      onClose={toggleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModal}>
        <div 
          style={{
            // height:'50%',
            width:'75%',
            backgroundColor:'white',
            borderRadius:5,
            overflow:'hidden'
          }}
        >
          <StepperComponent
            toggleSnackBar={toggleSnackBar}
            toggleModal={toggleModal}
          />
        </div>
      </Fade>
    </Modal>
  </div>

  )
}


function getSteps() {
  return ['Verify Gainsfolio Credentials', 'Add Wallet Address / Verify Pin', 'Done!'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Verify Credentials';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown step';
  }
}

const StepperComponent = ({
  toggleSnackBar,
  toggleModal
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [accountId, setAccountId] = useState('');

  const [sessionPin, setSessionPin] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const steps = getSteps();

  const isStepOptional = (step) => {
    // return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const onVerifyCredentials = async() => {
    console.log('VERIFY', username, password)
    setIsLoading(true)
    let verifyCredentialsResponse = await PostRequest(
      `https://gainsfolio-dev.com/api/defi/createPairSession`, 
      {
        email: username,
        password
      }
    );
    if(!verifyCredentialsResponse.error){
      const { accountId:newAccountId } = verifyCredentialsResponse;
      newAccountId&&setAccountId(newAccountId);
      handleNext();
      if(window.ethereum){
        try {
            const web3 = new Web3(window['ethereum']);
            const accounts = await window.ethereum.enable();
      // preSaleContract = new web3.eth.Contract(preSaleAbi,preSaleAddress);
            console.log("Web3 connected 1", accounts)
            accounts?.[0]&&setWalletAddress(accounts[0]);
            setIsLoading(false)

        }catch(e) {
            //console.log(e)
        }
      }else if (window.web3) {
          const web3 = new Web3(web3.currentProvider);
          const accounts = await web3.eth.getAccounts();
      // preSaleContract = new web3.eth.Contract(preSaleAbi,preSaleAddress);
          console.log("Web3 connected 2", accounts);
          accounts?.[0]&&setWalletAddress(accounts[0])
          setIsLoading(false)
      }else{
        //cant pair
      }
    }
    setIsLoading(false)

    console.log('Response', verifyCredentialsResponse);
  }

  const verifyPinAndAddress = async() => {
    setIsLoading(true)
    console.log('acc id!', accountId)
    let addWalletResponse = await PostRequest(
      `https://gainsfolio-dev.com/api/defi/verifyAndPairAddress`, 
      {
        accountId,
        passCode:sessionPin,
        walletAddress
      }
    );

    console.log('Add Wall?', addWalletResponse)
    if(!addWalletResponse.error){
      toggleSnackBar()
      handleNext();
      setTimeout(()=>{
        toggleModal()
      },2000)
    }
    // handleNext();
  }

  return (
    // <div className={classes.root}>
    <div 
      style={{
        flex:1,
        flexDirection:'column',
        display:'flex'
      }}
    >

      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel  
              StepIconProps={{ classes:{ root: classes.icon, active: classes.activeIcon, completed: classes.completedIcon } }}
                style={{color:'red'}} 
                {...labelProps}>{label}
                </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {
        activeStep===0&&
          <div
            style={{
              display:'flex',
              flex:2,
              flexDirection:'column',
              padding:25,
              justifyContent: 'space-between',
              alignItems:'center'
            }}
          >
            {
              isLoading&&
                <BorderLinearProgress  
                  style={{
                    height:5,
                    width:'100%',
                    marginBottom:20
                  }}
                />
            }
           
            <forum
              style={{
                width:'100%',
                justifyContent:'center',
                alignItems:'center',
                display:'flex',
                flexDirection: 'column'
              }}
            >
                <TextField 
                  value={username} 
                  onChange={(e) => setUserName(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabel,
                      focused: classes.textFieldLabelFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.textFieldRoot,
                      focused: classes.textFieldFocused,
                      notchedOutline: classes.textFieldNotchedOutline
                    }
                  }}
                  style={{}} 
                  id="outlined-basic" 
                  label="User Name" 
                  variant="outlined"
                  fullWidth={true}
                />
                <TextField 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabel,
                      focused: classes.textFieldLabelFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.textFieldRoot,
                      focused: classes.textFieldFocused,
                      notchedOutline: classes.textFieldNotchedOutline
                    }
                  }}
                  style={{marginTop: 20}} 
                  id="outlined-basic" 
                  label="Password" 
                  type="password"
                  variant="outlined"
                  fullWidth={true}
                />
                <Button 
                  size={'medium'} 
                  fullWidth={false} 
                  variant="contained" 
                  color="secondary"
                  style={{
                    minWidth: 150, 
                    minHeight: 40, 
                    marginTop: 20, 
                    justifySelf:'center',
                    alignSelf: 'center'
                }}
                  onClick={() => onVerifyCredentials() }
                  
                >
                  Verify
                </Button>
              </forum>

          </div>
      }
       {
        activeStep===1&&
          <div
            style={{
              display:'flex',
              flex:2,
              flexDirection:'column',
              padding:25,
              justifyContent: 'space-between',
              alignItems:'center'
            }}
          >
            {
              isLoading&&
                <BorderLinearProgress  
                  style={{
                    height:5,
                    width:'100%',
                    marginBottom:20
                  }}
                />
            }
            <forum>
                <TextField 
                  value={sessionPin} 
                  onChange={(e) => setSessionPin(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabel,
                      focused: classes.textFieldLabelFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.textFieldRoot,
                      focused: classes.textFieldFocused,
                      notchedOutline: classes.textFieldNotchedOutline
                    }
                  }}
                  style={{}} 
                  id="outlined-basic" 
                  label="Session Pin" 
                  variant="outlined"
                  type="number"
                  fullWidth={false}
                />
                <TextField 
                  value={walletAddress} 
                  onChange={(e) => setPassword(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabel,
                      focused: classes.textFieldLabelFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.textFieldRoot,
                      focused: classes.textFieldFocused,
                      notchedOutline: classes.textFieldNotchedOutline
                    }
                  }}
                  style={{marginTop: 20}} 
                  id="outlined-basic" 
                  label="New Wallet Address" 
                  variant="outlined"
                  fullWidth={true}
                  disabled={true}
                />
                <Button 
                  size={'medium'} 
                  fullWidth={false} 
                  variant="contained" 
                  color="secondary"
                  style={{maxWidth: 250, maxHeight: 100, marginTop: 20}}
                  onClick={() => verifyPinAndAddress() }
                >
                  Submit
                </Button>
              </forum>

          </div>
      }
      {
        activeStep===2&&
          <div
            style={{
              display:'flex',
              flex:2,
              flexDirection:'column',
              padding:25,
              justifyContent: 'space-between',
              alignItems:'center'
            }}
          >
            {/* <Button 
              size={'medium'} 
              fullWidth={false} 
              variant="contained" 
              color="secondary"
              style={{maxWidth: 250, maxHeight: 100, marginTop: 20}}
              onClick={() => verifyPinAndAddress() }
            >
              Close
            </Button> */}

          </div>
      }
      
      {/* <div
        style={{
          flex:1
        }}
      >
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div> */}
    </div>
  )
}





const SplashScreen = ({
  toggleModal
}) => {
  return (
    <div
      style={{
        // backgroundColor:'red',
        height:'100vh',
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column'

      }}
    >
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          // backgroundColor:'blue',
          justifyContent:'center',
          alignItems:'center'
        }}
      >
        <p className='font-gravityLight' style={{ fontSize: 65}}>Gainsfoli</p>
        <img
          style={{
            height:52,
            width:52,
            marginLeft:-2,
            marginTop:13
          }}
          src={AllOut}
          className='rotating'
        />
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          // backgroundColor:'blue',
          justifyContent:'center',
          alignItems:'center'
        }}
      >
        <div
          style={{

            // justifyContent:'center',
            // alignItems:'center',
            // display:'flex'
          }}
          className='pariWalletButton'
        >
             <div
                style={{
                    display:'flex',
                    height:50,
                    width:150,
                    borderRadius:3,
                    justifyContent:'center',
                    alignItems:'center'
                }}
                onClick={toggleModal}

            >
                {/* <h6 style={{margin:0}}> 🚀 Buy now! </h6> */}
                <p className='font-gravityLight' style={{ fontSize: 20, textAlign:'center', margin:0}}>Pair Wallet</p>

            </div>
        </div>
        
      </div>


    </div>
  )
}

export default App;
