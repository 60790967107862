const axios = require('axios');

export default async(url,_body) => {
    console.log("Called", _body)
    try {

        let signUpResult = await new Promise((resolve, reject) => {
                axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json', // whatever you want
                },
                data: _body,
                })
              .then(response => {
                  console.log("GOT RESPONCE!", response.data)
                  resolve(response.data)
              })
              .catch(error => {
                console.log(error)
                reject(error)
            });
        });
        
            // console.log("c2", signUpResult)
        
            return signUpResult
        
    } catch (error) {
        
    }
}
